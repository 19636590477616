
import { Vue, Component } from 'vue-property-decorator';
import PrivacyPolicyC from '@/components/PrivacyPolicy.vue';

@Component({
  name: "PrivacyPolicy",
  components: {
    PrivacyPolicyC
  },
  metaInfo: { 
    title: 'Privacy Policy | SkillToon - Master New Skills with Live Interactive Workshops',
    link: [
      { rel: 'canonical', href: 'https://skilltoon.com/privacy-policy' }
    ],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/login' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Privacy Policy | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'og:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'og:image', content: 'https://skilltoon.com/logo-color.svg' },
      { name: 'description', content: 'Dive into our immersive live workshops where every session is a stepping stone towards mastering your skills, connecting with experts, and transforming your passion into expertise. Join SkillToon to start shaping your future today!' },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/login' },
      { property: 'twitter:title', content: 'Privacy Policy | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'twitter:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'twitter:image', content: 'https://skilltoon.com/logo-color.svg' },
    ]
  }
})
export default class extends Vue{}
